import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    height: '100vh',
  },
  title: {
    marginTop: '24px',
    fontSize: '20px',
    fontWeight: '700',
    color: 'white',
    textAlign: 'center',
    letterSpacing: '-0.41px',
    marginBottom: '8px',
  },
  description: {
    fontSize: '12px',
    color: colors.white,
    textAlign: 'center',
    marginBottom: '32px',
  },
  typographyDaily: {
    color: 'white',
    textTransform: 'inherit',
    wordWrap: 'break-word',
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: '12px',
  },
  typographyTasks: {
    marginTop: '16px',
    color: 'white',
    textTransform: 'inherit',
    wordWrap: 'break-word',
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: '12px',
  },
};
