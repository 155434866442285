import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Route, Routes, Link, useLocation} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import WebApp from '@twa-dev/sdk';
import {CoinsWithBackground, DeviceCheck, HomeButton, NavigationButton, Onboarding, UsuallyModal} from './components';
import {ComingSoon, FightResult, Friends, Home, Other, Reward, SearchingOpponents, Store} from './pages';
import {
  useAddIncomeMutation,
  useAppDispatch,
  useAppSelector,
  useLazyGetLevelsQuery,
  useLazyGetOrCreateUserQuery,
  useLazyGetReferralQuery,
  useLazyUpdateUserQuery,
} from './hooks';
import {
  resetUserAction,
  selectUser,
  setCoinsAction,
  setInitialUserData,
  setUserDataAction,
  updateBackgroundMining,
  updateUserAction,
} from './reducers/user';
import {BottomNavigation, Box} from '@mui/material';
import {bottomNavigationStyle, linkStyle, styles} from './styles';
import {TUser} from './types/entities';
import {addOrUpdateElements, calculateNewCoinsFromBackground} from './helpers';
import {selectGlobal, setFirstOpenAppAction} from './reducers/global';
import DailyRewards from './pages/DailyRewards/DailyRewards';
import {resetReferralAction, selectReferral, setReferralDataAction} from './reducers/referral';
import Game from './pages/Game/Game';
import usePageUnload from './hooks/usePageUnload';
import useCheckLevels from './hooks/useCheckLevels';
import {selectLevels, setLevelsDataAction} from './reducers/levels';
import useBackgroundMiningDebounced from './hooks/useBackgroundMiningDebounced';

declare global {
  interface Window {
    Telegram: any;
  }
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const App = (props: any) => {
  usePageUnload();
  useCheckLevels();
  // useBackgroundMiningDebounced();

  const [loading, setLoading] = useState(true);
  const [getOrCreateUserQuery] = useLazyGetOrCreateUserQuery();
  const [updateUserQuery] = useLazyUpdateUserQuery();
  const [getReferralQuery] = useLazyGetReferralQuery();
  const [addIncome] = useAddIncomeMutation();

  const dispatch = useAppDispatch();
  const {data: user, backgroundMining, lastCoinUpdate, initialUserData} = useAppSelector(selectUser);
  const {total_earn: referralTotalEarn} = useAppSelector(selectReferral);
  const {firstOpenApp} = useAppSelector(selectGlobal);
  const [route, setRoute] = useState<'Mine' | 'Friends' | 'Reward' | 'Store' | 'Other'>('Mine');
  const intervalRef = useRef<any>(null);
  const [newCoins, setNewCoins] = useState<number | null>(null);
  const query = useQuery();

  const getBackgroundFarming = async () => {
    try {
      if (user?.id && initialUserData) {
        const {server_time} = initialUserData;

        // const {data} = await getReferralQuery({user_id: user.id});
        // const newReferralCoins = (data?.total_earn ?? referralTotalEarn) - referralTotalEarn;

        if (server_time && lastCoinUpdate && backgroundMining) {
          const backgroundCoinsNew = calculateNewCoinsFromBackground(server_time, lastCoinUpdate, backgroundMining);

          if (backgroundCoinsNew && backgroundCoinsNew >= 1) {
            // updateUser(
            //   Object.assign({}, user, {coins_num: (user?.coins_num ?? 0) + backgroundCoinsNew + newReferralCoins}),
            // );
            setNewCoins(backgroundCoinsNew);
            await addIncome({coins_num: parseInt(backgroundCoinsNew.toFixed(0), 10), id: user.id});
          } else {
            // updateUser(Object.assign({}, user, {coins_num: (user?.coins_num ?? 0) + newReferralCoins}));
          }
        } else {
          // updateUser(Object.assign({}, user, {coins_num: (user?.coins_num ?? 0) + newReferralCoins}));
        }
        // if (data) {
        //   dispatch(setReferralDataAction(data));
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialData = async () => {
    const initialData = WebApp.initData;
    const referral_code = query.get('referral_code');
    const chat_id = query.get('chat_id');

    try {
      const response = await getOrCreateUserQuery(
        `${initialData}&referral_code=${referral_code}&chat_id=${chat_id}`,
      ).unwrap();
      // const response = await getOrCreateUserQuery(
      //   `query_id=AAGS5lEUAAAAAJLmURSq-1hY&user=%7B%22id%22%3A340911762%2C%22first_name%22%3A%22%D0%90%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22af_andrew%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719852758&hash=a0357bae5df0a05a96653c8ec38c259bac112eed7c85bf1cd3592b8768098556&referral_code=${referral_code}`,
      // ).unwrap(); //Andrei

      // const response = await getOrCreateUserQuery(
      //   `query_id=AAG_b3FTAAAAAL9vcVNjviFL&user=%7B%22id%22%3A1399943103%2C%22first_name%22%3A%22Victoriya%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SViktoriyaS%22%2C%22language_code%22%3A%22uk%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1723725800&hash=c8f7982e8a8572f6b9bf061e3919abf8aae22a0e51d0e3e5d7d2cd11bfc72aa4&referral_code=null&chat_id=1399943103
      // `,).unwrap(); //Vika

      const {server_time} = response;

      const {data} = await getReferralQuery({user_id: response.id});

      if (response && response.user_tg_id && response.id) {
        dispatch(resetUserAction());
        dispatch(setUserDataAction(response));
        dispatch(setInitialUserData(response));
        dispatch(updateBackgroundMining());
        if (data) {
          dispatch(setReferralDataAction(data));
        } else {
          dispatch(resetReferralAction());
        }
        const updateCardArray = addOrUpdateElements(user?.cards ?? [], response.cards);
        dispatch(updateUserAction({cards: updateCardArray, server_time: response.server_time}));
      }

      // const {data} = await getReferralQuery({user_id: user.id});
      // const newReferralCoins = (data?.total_earn ?? referralTotalEarn) - referralTotalEarn;
      dispatch(updateBackgroundMining());

      const newReferralCoins = (data?.total_earn ?? referralTotalEarn) - referralTotalEarn;

      if (server_time && lastCoinUpdate && backgroundMining) {
        const backgroundCoinsNew = calculateNewCoinsFromBackground(server_time, lastCoinUpdate, backgroundMining);

        if (backgroundCoinsNew && backgroundCoinsNew >= 1) {
          // updateUser(
          //   Object.assign({}, user, {coins_num: (user?.coins_num ?? 0) + backgroundCoinsNew + newReferralCoins}),
          // );
          setNewCoins(backgroundCoinsNew);
          await addIncome({coins_num: parseInt(backgroundCoinsNew.toFixed(0), 10), id: response.id});
        } else {
          // updateUser(Object.assign({}, user, {coins_num: (user?.coins_num ?? 0) + newReferralCoins}));
        }
      } else {
        // updateUser(Object.assign({}, user, {coins_num: (user?.coins_num ?? 0) + newReferralCoins}));
      }
      // if (data) {
      //   dispatch(setReferralDataAction(data));
      // }

      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (isMobile) {
      WebApp.expand();
      getInitialData();
    }
  }, []);

  const hasExecutedRef = useRef(false);

  useEffect(() => {
    if (initialUserData && user && lastCoinUpdate && !hasExecutedRef.current) {
      getBackgroundFarming();
      hasExecutedRef.current = true;
    }
  }, [initialUserData, lastCoinUpdate]);

  const updateUser = async (user: TUser) => {
    try {
      const userDataFormat: TUser = Object.assign({}, user, {coins_num: Math.floor(user.coins_num)});
      const {data} = await updateUserQuery(JSON.stringify(userDataFormat));
      dispatch(updateUserAction(data));
    } catch (error) {}
  };

  const handleRoutePress = useCallback((route: 'Mine' | 'Friends' | 'Reward' | 'Store' | 'Other') => {
    setRoute(route);
  }, []);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (backgroundMining !== 0) {
      intervalRef.current = setInterval(() => {
        dispatch(setCoinsAction(backgroundMining));
      }, 1000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [backgroundMining, dispatch]);

  const handleApprove = useCallback(() => {
    dispatch(setCoinsAction(newCoins));
    setNewCoins(null);
  }, [newCoins]);

  const handleCloseOnboarding = useCallback(() => {
    dispatch(setFirstOpenAppAction(false));
  }, []);

  if (!isMobile) {
    return <DeviceCheck />;
  }

  if (loading || firstOpenApp) {
    return <Onboarding onClose={handleCloseOnboarding} loading={loading} firstOpenApp={firstOpenApp} />;
  }

  return (
    <>
      <Box>
        <div className="content">
          <Routes>
            <Route path="/reward" element={<Reward />} />
            <Route path="/reward/daily" element={<DailyRewards />} />
            <Route path="/store" element={<Store />} />
            <Route path="/" element={<Home />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/other" element={<Other />} />
            <Route path="/fights" element={<ComingSoon />} />
            <Route path="/search-opponents" element={<SearchingOpponents />} />
            <Route path="/game" element={<Game />} />
            <Route path="/fight-result" element={<FightResult />} />
          </Routes>
        </div>
        <Box sx={styles.bottomNavigationContainer}>
          <BottomNavigation style={bottomNavigationStyle}>
            <Link onClick={() => handleRoutePress('Reward')} style={linkStyle} to="/reward">
              <NavigationButton isActive={route === 'Reward'} title="Винагорода" />
            </Link>
            <Link onClick={() => handleRoutePress('Store')} style={linkStyle} to="/store">
              <NavigationButton isActive={route === 'Store'} title="Магазин" />
            </Link>
            <Link onClick={() => handleRoutePress('Mine')} style={linkStyle} to="/">
              <HomeButton onClick={() => handleRoutePress('Mine')} />
            </Link>
            <Link onClick={() => handleRoutePress('Friends')} style={linkStyle} to="/friends">
              <NavigationButton isActive={route === 'Friends'} title="Друзі" />
            </Link>
            <Link onClick={() => handleRoutePress('Other')} style={linkStyle} to="/other">
              <NavigationButton isActive={route === 'Other'} title="Інше" />
            </Link>
          </BottomNavigation>
        </Box>
      </Box>
      <UsuallyModal isVisible={!!newCoins} handleClose={handleApprove}>
        {newCoins ? <CoinsWithBackground onApprove={handleApprove} value={newCoins} /> : null}
      </UsuallyModal>
    </>
  );
};

export default App;
